<template>
<v-app>


  <v-container
    id="dashboard"
    fluid
    tag="section">
    
    <v-row>

      <v-col cols="12" sm="8" lg="6">
          <v-card-title class="text-h3">
            <v-icon style="color:#FFD700;font-size:48px">mdi-trophy</v-icon>  &nbsp; Ranking
          </v-card-title>
        <apexcharts style="margin-left:50px" width="580" height="350" type="bar" :options="chartOptions" :series="series"></apexcharts>
      </v-col>  


      <v-col
          cols="12"
          sm="6"
          lg="2">
      </v-col> 
  
      <v-col
        cols="12"
        sm="6"
        lg="4">

        <v-card
            color="#fb8c00"
            dark>
            <v-card-title class="text-h3">
                   <v-icon>mdi-trophy</v-icon>  &nbsp; Pontos
            </v-card-title>
            <br/>
           <v-card-title > <h1>{{this.saldo}}</h1></v-card-title>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
        <v-col  cols="12" sm="6" lg="4">
          <v-card
            color="#4da2b2"
            dark
          >
          <v-card-title class="text-h5">
                   <v-icon>mdi-gift</v-icon>  &nbsp; Pontuação Utilizada
            </v-card-title>
            <br/>
           <v-card-subtitle> <h1>{{this.total_premiado}}</h1></v-card-subtitle>

          </v-card>
        </v-col>

        <v-col cols="12" sm="6" lg="4">
          <v-card
            color="#4da2b2"
            dark
          >
          <v-card-title class="text-h5">
                           <v-icon>mdi-trophy</v-icon>  &nbsp; Pontuação Acumulada - Histórico
            </v-card-title>
            <br/>
           <v-card-subtitle> <h1>{{this.total_acumulado}}</h1></v-card-subtitle>

          </v-card>
        </v-col>

        <v-col cols="12" sm="6" lg="4">
          <v-card
            color="#4da2b2"
            dark
          >
          <v-card-title class="text-h5">
              <v-icon>mdi-ticket</v-icon> &nbsp;  TOKEN
            </v-card-title>
            <br/>
           
           <v-card-subtitle> <h1>{{this.token}}</h1></v-card-subtitle>

          </v-card>
        </v-col>



      <v-col
       cols="12"
        sm="6"
        lg="3"
      >
       
        <base-material-stats-card
          color="red"
          icon="mdi-map-marker-radius"
          title=" Endereço"
          sub-icon-color="red"
           :value='this.total_endereco'
          sub-text="Quantidade Acumulada"
        />
       
      </v-col>

       <v-col
       cols="12"
        sm="6"
        lg="3"
      >
       
        <base-material-stats-card
          color="black"
          icon="mdi-at"
          title="Email"
           :value='this.total_email'
          sub-icon-color="red"
          sub-text="Total Acumulado"
        />
       
      </v-col>


             <v-col
       cols="12"
        sm="6"
        lg="3"
      >
       
        <base-material-stats-card
          color="blue"
          icon="mdi-phone"
          title="Telefone"
           :value='this.total_telefone'
          sub-icon-color="red"
          sub-text="Total Acumulado"
        />
       
      </v-col>


       <v-col
       cols="12"
        sm="6"
        lg="3">
       
        <base-material-stats-card
          color="purple"
          icon="mdi-shopping"
          title="Vendas"
           :value='this.total_vendas'
          sub-icon-color="red"
          sub-text="Total Acumulado"
        />
       
      </v-col>
       <v-col
       cols="12"
        sm="6"
        lg="3"
      >
       
        <base-material-stats-card
          color="green"
          icon="mdi-hospital-box"
          title="Previda Mais"
           :value='this.total_previdamais'
          sub-icon-color="red"
          sub-text="Total Acumulado"
        />
       
      </v-col>

          <v-col
       cols="12"
        sm="6"
        lg="3"
      >
       
        <base-material-stats-card
          color="orange"
          icon="mdi-arrow-top-right-bold-box"
          title="Indicação"
           :value='this.total_indicacao'
          sub-icon-color="orange"
          sub-text="Total Acumulado"
        />
       
      </v-col>

      <v-col
       cols="12"
        sm="6"
        lg="3"
      >
       
        <base-material-stats-card
          color="brown"
          icon="mdi-arrow-top-right-bold-box"
          title="Fatura Digital"
           :value='this.total_carne'
          susb-icon-color="orange"
          sub-text="Total Acumulado"
        />
       
      </v-col>
             
    </v-row>

     

<base-material-card
      icon="mdi-trophy"
      title="Últimas Atividades"
      class="px-5 py-3">

    <v-data-table
      :headers="headers_eventos"
      :items="eventos"
      :items-per-page="5"
      class="elevation-1">
    </v-data-table>
 </base-material-card>

<base-material-card
      icon="mdi-gift"
      title="Histórico de Prêmios"
      color="info"
      class="px-5 py-3"
    >

      <v-data-table
      :headers="headers_premiacoes"
      :items="premiacoes"
      :items-per-page="5"
      class="elevation-1">
      
      <template v-slot:item="row">
          <tr>
            <td style="width: 15%;font-size:14px;">{{row.item.data_insert}}</td>
            <td style="width: 25%;font-size:14px;">{{row.item.evento}}</td>
            <td style="width: 15%;font-size:14px;">{{row.item.pontos}}</td>

            <td style="width: 15%;font-size:14px;">
                    <v-chip
                      :color="getBadge(row.item.situacao)"
                      dark
                    >
                      {{row.item.situacao}}               
                    </v-chip>
              
            </td>
        </tr>
       </template> 
    
    </v-data-table>


    </base-material-card>
 
  

  </v-container>

</v-app> 
</template>

<script>
  import Promotor from '../../services/promotor'
 import VueApexCharts from 'vue-apexcharts'



  export default {
  components: {
    apexcharts: VueApexCharts,
  },
    mounted() {
        this.ranking();

          Promotor.eventos_promotor().then(response => {
                if(response.status = 200){
                  this.eventos = response.data;
                }
              }).catch(e => {
              }).finally(() => {
            });

      Promotor.premiacao_promotor().then(response => {
                if(response.status = 200){
                  this.premiacoes = response.data;
                }
              }).catch(e => {
              }).finally(() => {
            });


          Promotor.totais_dash().then(response => {
                if(response.status = 200){
                   this.saldo = response.data.saldo;
                   this.total_endereco = response.data.tcend;
                   this.total_telefone = response.data.tctel;
                   this.total_email = response.data.te;
                   this.total_vendas = response.data.tv;
                   this.total_previdamais = response.data.tpr;
                   this.total_acumulado = response.data.tacum;
                   this.total_premiado = response.data.tpre;
                   this.total_indicacao = response.data.tind;
                  this.total_carne = response.data.tcarne;
                   this.token = response.data.token;
                   this.key = response.data.key;
                  sessionStorage.setItem('token', this.token);
                  sessionStorage.setItem('key', this.key);


                  //this.total_contato = response.data.mensagem;
                }
              }).catch(e => {
                 if(e.response.status === 401){
                  this.$store.dispatch('auth/logout');
                  this.$router.push('/login');
                  }
              }).finally(() => {
            });
      
    },

    name: 'DashboardDashboard',
 
    data () {
      return {
        saldo: '0',
        token: '',
        promotores_pontos: [],
        promotores_nomes: [],
        key: '',
        total_acumulado: 0,
        total_premiado: 0,
        total_vendas: 0,
        total_endereco: 0,
        total_email: 0,
        total_telefone: 0,
        total_indicacao: 0,
        total_contato_end: 0,
        total_contato_ema: 0,
        total_previdamais: 0,
        total_carne: 0,

        headers_eventos: [
          { text: 'Data', value: 'data_insert' },
          { text: 'Evento', value: 'evento' },
          { text: 'Contrato', value: 'contrato' },
          { text: 'Pontos', value: 'pontos' },
          { text: 'Status', value: 'alerta' },

        ],

        headers_premiacoes: [
          { text: 'Data', value: 'data_insert' },
          { text: 'Evento', value: 'evento' },
          { text: 'Pontos', value: 'pontos' },
          { text: 'Situação', value: 'situacao' },

        ],




        eventos: [],
        premiacoes:[],
        ev:{
          token:'',
          evento: '',
          valorantigo: '',
          valornovo: '',
          contrato: ''
        },

         series: [{
            data: [],
          }],
          chartOptions: {
            
          },

      } 
    },

    methods: {

      incluir(){
            Promotor.incluirPontoToken(this.ev).then(response => {
              }).catch(e => {
              }).finally(() => {
            })

      },
      
      ranking(){
           Promotor.ranking_saldo().then(response => {
               if(response.status = 200){
                  this.promotores_nome = response.data.lista_nome;
                  this.promotores_pontos = response.data.lista_pontos;

                    this.series =  [{
                      data: this.promotores_pontos
                    }]

                    this.chartOptions = {
                                  chart: {
                                    type: 'bar',
                                    height: 350
                          },
                          plotOptions: {
                            bar: {
                              borderRadius: 4,
                              horizontal: true,
                            }
                          },
                         
                          dataLabels: {
                            enabled: true,
                            textAnchor: 'start',
                            style: {
                              colors: ['#fff']
                            },
                           
                          },
                           colors: ['#33b2df', '#546E7A', '#d4526e', '#13d8aa', '#A5978B', '#2b908f', '#f9a3a4', '#90ee7e','#f48024', '#69d2e7'
                          ],
                          xaxis: {
                            categories: this.promotores_nome,
                            trim: true,
                            labels: {
                              show: false,
                              
                            }
                          }
                    }






                }
              }).catch(e => {

              }).finally(() => {

            });
      },

       getBadge (status) {
        switch (status) {
           case 'APROVADO': return 'green lighten-1'
           case 'RECUSADO' : return 'red lighten-1'
          default: 'success'
        }
      },
    },
  }
</script>
